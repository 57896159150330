body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container {
    position: relative;
    width: 100%;
    height: 84vh; /* Full height of the viewport */
    overflow: hidden; /* Prevent container from being scrollable */
    margin-top : 100px;
}

.leftHalf, .rightHalf {
    position: absolute;
    height: 100%; /* Full height */
    width: 50%; /* Occupy 50% of the screen width */
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center content vertically */
    padding: 2%; /* Adjust as needed */
    box-sizing: border-box; /* Include padding and border in element's dimensions */
}

.leftHalf {
    flex: 1; /* Make the right half fill the available space */
    left: 0;
    border-right: 1px solid #ccc; /* Border style for separation */
    overflow-y: scroll; /* Allow vertical scrolling */

}

.rightHalf {
    flex: 1; /* Make the right half fill the available space */
    right: 0;
    overflow-y: auto; /* Hide content overflow */
}

.task-card {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 1px 1px 1px 1px;
  margin-bottom: 20px;
}

.task-name-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 30px;
}

.time-selection {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.second-select,
.hour-select,
.minute-select {
  width: 80px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
}

.separator {
  margin-right: 5px;
}

.button-group {
  display: flex;
}

.create-button,
.start-button,
.remove-button {
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  border-width:5px;

}

.create-button {
  background-color: #007bff;
  color: #fff;
  border-color:#007bff;
}

.generate-task-file,
.lets-create-task-button {
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 4px;
    cursor: pointer;
    border-width:10px;
}

.lets-create-task-button {
    background-color: #007bfa;
      color: #fff;
      border-color : #007bfa;
}

.generate-task-file {
  background-color:  #00cc99;
  color: #333;
  border-color : #33ffcc;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.start-button {
  background-color: #28a745;
  color: #fff;
  border-color : #28a745;
}

.remove-button {
  background-color: #dc3545;
  color: #fff;
  border-color : #dc3545;
}
