/* Header styles */
app-container {
  height:100%;
  position: fixed;
  overflow: hidden;
}

header {
  background-color: #007bff;
  color: white;
  padding: 2px;
  text-align: center;
  top:0;
}

/* Main content styles */
main {
  flex: 1;
  overflow: hidden;
}

.footer {
  background-color: #f2f2f2;
  padding: 20px;
  margin : 30px;
}

.footer p {
  margin: 0;
}

.footer-nav a {
  margin: 0 10px;
  color: #333;
  text-decoration: none;
}